import React, { useState } from 'react';
import PageTitle from '../components/pagetitle';
import loadingGif from '../assets/images/loading.gif';

const surpriseMePrompts = [
    'an armchair in the shape of an avocado',
    'a surrealist dream-like oil painting by Salvador Dalí of a cat playing checkers',
    'teddy bears shopping for groceries in Japan, ukiyo-e',
    'an oil painting by Matisse of a humanoid robot playing chess',
    'panda mad scientist mixing sparkling chemicals',
    "a macro 35mm photograph of two mice in Hawaii, they're each wearing tiny swimsuits and are carrying tiny surf boards, digital art",
    '3D render of a cute tropical fish in an aquarium on a dark blue background, digital art',
    'an astronaut lounging in a tropical resort in space, vaporwave',
    'an oil painting portrait of a capybara wearing medieval royal robes and an ornate crown on a dark background',
    'a stained glass window depicting a hamburger and french fries',
    'a pencil and watercolor drawing of a bright city in the future with flying cars',
    'a sunlit indoor lounge area with a pool with clear water and another pool with translucent pastel pink water, next to a big window, digital art',
    'a fortune-telling shiba inu reading your fate in a giant hamburger, digital art',
    '"a sea otter with a pearl earring" by Johannes Vermeer',
    'an oil pastel drawing of an annoyed cat in a spaceship',
    'a painting of a fox in the style of Starry Night',
    'a bowl of soup that looks like a monster, knitted out of wool',
    'A plush toy robot sitting against a yellow wall',
    'A synthwave style sunset above the reflecting water of the sea',
    'Two futuristic towers with a skybridge covered in lush foliage',
    'A 3D render of a rainbow colored hot air balloon flying above a reflective lake',
    'A comic book cover of a superhero wearing headphones',
    'A centered explosion of colorful powder on a black background',
    'A photo of a Samoyed dog with its tongue out hugging a white Siamese cat',
    'A photo of a white fur monster standing in a purple room',
    "A photo of Michelangelos sculpture of David wearing headphones djing",
    'A Samurai riding a Horse on Mars, lomography.',
    'A modern, sleek Cadillac drives along the Gardiner expressway with downtown Toronto in the background, with a lens flare, 50mm photography',
    'A realistic photograph of a young woman with blue eyes and blonde hair',
    'A man standing in front of a stargate to another dimension',
    'Spongebob Squarepants in the Blair Witch Project',
    'A velociraptor working at a hotdog stand, lomography',
    'A man walking through the bustling streets of Kowloon at night, lit by many bright neon shop signs, 50mm lens',
    'A BBQ that is alive, in the style of a Pixar animated movie',
    'A futuristic cyborg dance club, neon lights',
    'The long-lost Star Wars 1990 Japanese Anime',
    'A hamburger in the shape of a Rubiks cube, professional food photography',
    'A Synthwave Hedgehog, Blade Runner Cyberpunk',
    'An astronaut encountering an alien life form on a distant planet',
    'A Dinosaur exploring Cape Town, photography',
    'A Man falling in Love with his Computer',
    'A photograph of a cyborg exploring Tokyo at night, lomography',
    'Dracula walking down the street of New York City in the 1920s',
    'Synthwave aeroplane',
    'A man wanders through the rainy streets of Tokyo, with bright neon signs, 50mm',
    'A Space Shuttle flying above Cape Town',
  ];

function Generate(props) {
    
    const [loading, setLoading] = useState(false);
    const [prompt, setPrompt] = useState(false);
    

    const valueChanged = (event) => {        
        setPrompt(event.currentTarget.value)
    }

    const surpriseClick = () => {
        const value = surpriseMePrompts[Math.trunc(Math.random() * 47)]        
        setPrompt(value)
    }

    function getPictures(id, counter)
		{

            const requestOptions = {
                method: 'GET',                                               
                crossorigin: true,    
                mode: 'no-cors', 
            };

            fetch('https://firefly.fogoros.ro/getPictures.php?promptId='+id, requestOptions)
                .then(response => {
                    console.log(response)
                })
                .then(data => {
                    console.log(data)
                    //setTimeout(function() { getPictures(data, 0) }, 1000);
                });

			// $.ajax({             
			// 	 url: "/getPictures.php?promptId="+id,
			// 	 type: "GET",				 
			// 	 success: function(data, status, xhr) {
			// 		const pictures = JSON.parse(data);
			// 		if (pictures.length > 0) {
			// 			document.querySelector("#resultsContainer").html("");
			// 			let html = ""
			// 			for(var i=0;i<pictures.length;i++) {
			// 				html+="<img src='" + pictures[i].path + "' style='margin-right:10px; margin-bottom:10px; width:350px; display:inline-block'/>";
			// 			}
			// 			document.querySelector("#resultsContainer").html(html);
			// 		} else {
			// 			if (counter < 60) {
			// 				setTimeout(function() { getPictures(id, counter+1) }, 1000);
			// 			} else {
			// 				document.querySelector("#resultsContainer").html("OOPS something when wrong, please try again later");
			// 			}
			// 		}
			// 	 },
			// 	 error: function (xhr, ajaxOptions, thrownError) {
			// 		 alert("status: " + xhr.status + ", error: " + thrownError);
			// 	 },			  
			// 	});
		}

    const generateClick = () => {
            setLoading(true)
            //const data = new FormData();
            //data.append("prompt", prompt);
            const requestOptions = {
                method: 'POST',                 
                //crossorigin: true,    
                //mode: 'no-cors',   
                body: "prompt=" + prompt,   
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
                }                                    
            };            
            fetch('/savePrompts.php', requestOptions)
            .then(async (res) => ({
                status: res.status,
                body: await res.text()
              })).then(({ status, body }) => {
                console.log(status); // this is the status code
                console.log(body);   // this is json body 
              })
              .catch(error => {
                console.log("error", error);
              });
                //     response => response.json()                    
                // )
                // .then(data => {
                //     console.log(data)
                //    // setTimeout(function() { getPictures(data, 0) }, 1000);
                // });

            // $.ajax({             
            //     url: "/savePrompts.php",
            //     type: "POST",
            //     data: "prompt=" + $("#prompt").val(),
            //     success: function(data, status, xhr) {
            //         var out = (data && typeof data === 'object') ? JSON.stringify(data) : data;
            //         setTimeout(function() { getPictures(data, 0) }, 1000);
            //         setLoading(true)
            //     },
            //     error: function (xhr, ajaxOptions, thrownError) {
            //         alert("status: " + xhr.status + ", error: " + thrownError);
            //     },
            //     beforeSend: function(request) { // Set JWT header
            //         request.setRequestHeader('Authorization', 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE2ODI3NTkzMzksImp0aSI6Imd1QzJ2ZlJ3aEJlOGJ3Vm1halFrQlNHNnZibmZGZDR2R3djZXBpYVJPbDQ9IiwiaXNzIjoibG9jYWxob3N0IiwibmJmIjoxNjgyNzU5MzM5LCJleHAiOjE2ODI3NjI5MzksInNlY3VyaXR5Ijp7InVzZXJuYW1lIjoiQWRtaW5pc3RyYXRvciIsInVzZXJpZCI6Ii0xIiwicGFyZW50dXNlcmlkIjpudWxsLCJ1c2VybGV2ZWxpZCI6LTF9fQ.m5eI9Ocuaea7vuzXq5b9Gg4vz9CWBdkoTZNMq0r64leIiaWx1z3L-9DlPtHlyo67Xo0ShUpHHMFAcytshtdBYA');
            //     } 
            //    });                
    }
    return (
        <div>
            <PageTitle title='Generate pictures' />

            <section className="tf-section tf-generate">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-md-12">
                            <p className="m-r-40">Prompt</p>
                            <div>
                                <fieldset>
                                    <input type="text" name="name" id="name" placeholder="Name" value={prompt} onChange={valueChanged}/>
                                </fieldset>    
                                <br/>                            
                                <button className="tf-button btn-effect" type="button" onClick={generateClick}><span className="boder-fade"></span><span className="effect">Generate</span></button>
                                &nbsp;&nbsp;&nbsp;
                                <button className="tf-button btn-effect" type="button" onClick={surpriseClick}><span className="boder-fade"></span><span className="effect">Surprise me</span></button>
                           </div>
                        </div>                        
                    </div>
                    <div>
                    <div id="resultsContainer"></div>
                    {loading && <div><br/><img src={loadingGif} style={{width:'30px'}}/> </div>}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Generate;